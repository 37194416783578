
const slideAwayForm = (div, button) => {
  button.addEventListener('click', () => {
    div.classList.remove('display')
    toggleTooltip(false)
  })
}

const slideOutForm = (div, button) => {
  button.addEventListener('click', () => {
    div.classList.add('display')
    toggleTooltip(true)
  })
}


export default {
  loadSliderForm() {
    let infoForm = document.getElementById('infoRequestFormSlider')

    if (infoForm) {
      let openButton = document.getElementById('infoRequestFormButton')
      if (openButton) { slideOutForm(infoForm, openButton) }

      let closeButton = infoForm.querySelector('i.close-form')
      if (closeButton) { slideAwayForm(infoForm, closeButton) }
    }
  }
}

function toggleTooltip(hide) {
  let tooltip = document.getElementById('svg-tooltip')
  if (tooltip) {
    if (hide) {
      tooltip.classList.add('hide')
    } else {
      tooltip.classList.remove('hide')
    }
  }
}


$(document)
  .on('click', '#to_gpe_form', function() {
    $('.slider-info-form').addClass('display')
    $('#info_request_first_name').focus()
  })
