
export default {

  showFirstEventImage() {
    let firstImage = document.getElementsByClassName('.event-banner-image-clone')[0]

    if (firstImage) {
      firstImage.classList.add('good-day-image')
    }
  },

  reExchangeImage() {
    let newImage = document.getElementById('eventJumboImage')
    let collection = document.querySelectorAll('.delay-image-load')

    collection.length > 0 ? collection[0].classList.add('good-day') : ''

    Array.from(collection).forEach(event => {
      let eventDataTitle = event.getAttribute('data-event-title')
      let clone = event.querySelector('.event-banner-image')

      if (eventDataTitle && clone) {
        clone.classList.add('.event-banner-image-clone')

        if (newImage) {
          newImage.appendChild(clone)
        }

        eventHoverOn(event, collection, eventDataTitle)
        eventHoverOff(event, collection)
      }
    })
  },

  exchangeImage() {
    let newImage = document.getElementById('eventJumboImage')
    let collection = document.querySelectorAll('.delay-image-load')

    collection.length > 0 ? collection[0].classList.add('good-day') : ''

    Array.from(collection).forEach(event => {
      let eventDataTitle = event.getAttribute('data-event-title')
      let clone = event.querySelector('.event-banner-image')

      if (eventDataTitle && clone) {
        clone.classList.add('.event-banner-image-clone')

        if (newImage) {
          newImage.appendChild(clone)
        }

        eventHoverOn(event, collection, eventDataTitle)
        eventHoverOff(event, collection)
      }
    })

    // good-day class applies hover-on/focus styles to object(s)
    // good-day-image class applies to the large banner image associated with the event in hover/focus
    // good-night class applies to all other siblings not in focus
  }
}
var timeout_hover

function eventHoverOn(event, eventCollection, eventDataTitle) {
  // timeout_hover = function(callback, event, x,y) {setTimeout(callback, 1000, event, x, y)}
  event.addEventListener('mouseover', function() {
    clearTimeout(timeout_hover)
    timeout_hover = setTimeout(addShowClass, 200, event, eventCollection, eventDataTitle)
  })
}

function eventHoverOff(event, eventCollection) {
  clearTimeout(timeout_hover)
  event.addEventListener('mouseout', function() {
    setTimeout(removeShowClass, 200, eventCollection)
  })
}

function addShowClass(event, eventCollection, eventDataTitle) {
  event.classList.add('good-day')
  for(var q = 0; eventCollection.length > q; q++) {

    if(eventCollection[q].getAttribute('data-event-title') != eventDataTitle) {
      eventCollection[q].classList.add('good-night')
      eventCollection[q].classList.remove('good-day')
    }
  }

  setTimeout(eventImageToogle(eventDataTitle), 1000)
}

function removeShowClass(eventCollection) {
  for(var x = 0; eventCollection.length > x; x++) {
    eventCollection[x].classList.remove('good-night')
  }
}

function eventImageToogle(eventDataTitle) {
  var images = document.getElementsByClassName('.event-banner-image-clone')
  for(var i = 0; images.length > i; i++) {

    if(images[i].getAttribute('data-event-title') == eventDataTitle) {
      images[i].classList.add('good-day-image')
    } else {
      images[i].classList.remove('good-day-image')
    }
  }
}

