document.addEventListener('turbo:load', () => {
  setTimeout(function() {
    let linearGradBg = document.querySelector('.linear-grad-bg .checkout-container')

    if(linearGradBg && (screen.width <= 991)) {

      let summary_container_div = document.querySelector('#summaryContainer .order-summary')

      summary_container_div.addEventListener('click', () => {
        document.querySelector('.linear-grad-bg').classList.toggle('close-summary')
      })
    }
  }, 1000)
})
