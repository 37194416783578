export default {
  loadFullDropdown() {
    var el = document.getElementById('f1TicketsLink')
    if (el) {
      el.addEventListener('click', openFullDropdown)
    }
  },
  captureEvent() {
    var lists = Array.from(document.querySelectorAll('ul.events-row > li'))
    if(lists) {
      lists.forEach(list => list.addEventListener('click', eventSelect))
    }
  },
  assignEventsOrder() {
    let currentYear = document.getElementById('currentYear')
    if(currentYear) {
      let currentEvents = currentYear.childElementCount
      let currentCount = Math.round(currentEvents/2)
      currentYear.setAttribute('style', `grid-template-rows: repeat(${currentCount}, 1fr)`)
    }


    let nextYear = document.getElementById('nextYear')
    if(nextYear) {
      let futureEvents = nextYear.childElementCount
      let futureCount = Math.round(futureEvents/2)
      nextYear.setAttribute('style', `grid-template-rows: repeat(${futureCount}, 1fr)`)
    }
  }
}

function eventSelect() {
  let body = document.querySelector('body')
  body.classList.remove('full-dropdown-open')

  let link = encodeURIComponent(this.getAttribute('data-side-link'))
  window.location.href = window.location.origin + link
}

function openFullDropdown() {
  let body = document.querySelector('body')
  body.classList.toggle('full-dropdown-open')
}

$(document)
/* currency dropdown */
  .on('click', function(event) {
    let drpdwn = $('#currency')

    if (!drpdwn.is(event.target) && drpdwn.has(event.target).length === 0){
      if ($('.currencies-dropdown').hasClass('show')) {
        $('.currencies-dropdown').removeClass('show')
      }
    }
  })

  .on('click', '#currency', function() {
    $('.currencies-dropdown').toggleClass('show')
  })

  .on('click', '.currencies-dropdown li', function() {
    $('.currencies-dropdown').toggleClass('show')
  })
