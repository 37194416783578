import {isGpePackagePage} from '../default/svg_package_zoom'

const hideTooltip = () => {
  let tooltip = document.getElementById('svg-tooltip')
  if (tooltip) {
    tooltip.style.display = 'none'
  }
}

const showTooltip = () => {
  let tooltip = document.getElementById('svg-tooltip')

  if (isGpePackagePage()) {
    tooltip.style.display = 'none'
  } else if (tooltip) {
    tooltip.style.display = 'block'
  }
}

document.addEventListener('turbo:load', () => {
  let elem = document.querySelector('.seating-chart-ticket-table')
  if (elem) {
    elem.addEventListener('mouseover', () => {
      showTooltip()
    })

    elem.addEventListener('mouseout', () => {
      hideTooltip()
    })
  }

  let svg_zoom_elem = document.querySelector('.gpe-package-page')
  if(svg_zoom_elem) {
    svg_zoom_elem.addEventListener('mouseover', () => {
      showTooltip()
    })
  }
})
