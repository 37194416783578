export default {
  closeSideNav() {
    var el = document.getElementById('sideNavCloseBtn')

    if(el) {
      el.addEventListener('click', function(){
        let nav = document.getElementById('mobileSideNav')
        nav.classList.remove('reveal')
        document.body.classList.remove('side-menu-open')
      })
    }
  },

  closeAllMenus() {
    var spacers = document.getElementsByClassName('side-nav-spacer')
    for(var i = 0; spacers.length > i; i++) {
      spacers[i].addEventListener('click', function(){
        closeSubMenus()
        closeParentMenu()
      })
    }
  },

  openSideNav() {
    var btn = document.getElementById('mobileNavBtn')

    if(btn) {
      btn.addEventListener('click', function() {
        let nav = document.getElementById('mobileSideNav')
        nav.classList.add('reveal')
        document.body.classList.add('side-menu-open')
      })
    }
  },

  assignSubMenuTarget() {
    Array.from(document.querySelectorAll('.side-nav-dropdown-link')).forEach(menuLink => {
      let menu = menuLink.getAttribute('data-menu-target')

      if(menu) {
        menuLink.addEventListener('click', () => { openSubMenu(menu) })
      }
    })
  },

  assignSubMenuBackBtn() {
    Array.from(document.querySelectorAll('.side-nav-back-btn')).forEach(button => {
      let menu = button.getAttribute('data-menu-target')

      if(menu) {
        button.addEventListener('click', () => { closeSubMenu(menu) })
      }
    })
  },
}


function openSubMenu(target) {
  document.getElementById(target).classList.add('reveal')
}

function closeSubMenu(target) {
  document.getElementById(target).classList.remove('reveal')
}

function closeSubMenus() {
  var childMenus = document.getElementsByClassName('side-nav-child-menu')
  for(var i = 0; childMenus.length > i; i++) {
    childMenus[i].classList.remove('reveal')
  }
}

function closeParentMenu() {
  document.getElementById('mobileSideNav').classList.remove('reveal')
}

/*

To use the side nav, you have to add a data attribute called "data-menu-target"
The value of the attribute must be the id of the coordinating menu

*/
