import '../shared/phone'
import './dropdown'
// import './bio-slide'
import './cookies'
import './h4h_form'
import './trustbox'
import './svg_zoom'
import './tooltip'
import './svg_package_zoom'
import '../shared/buyers_page_personalizer'
import '../shared/stripe_checkout'

import AddToCartForms from '../shared/add_to_cart_forms'
import HotelIndex from '../shared/add_to_cart_forms/h4h_hotels/hotel_index'
import { store } from'../default/store'
import VueLoader from '../default/vue-loader'
import AddToCart from './cart/add-to-cart'
import CartNav from './nav_cart'
import Checkout from '../shared/checkout'
import DepositRedemptions from '../shared/deposit_redemptions'
import ImageSwap from '../shared/image_swap'
// Sildeout Nav for Mobile
import SideNav from '../shared/nav/side_nav'
// Subnav for Event and Package Pages
import EventNav from './eventNav'

// Custom JS
import Dropdown from './dropdown'
import InfoFormSlider from './info_form_slider'

window.addEventListener('turbo:render', () => {
  Dropdown.captureEvent()
  let mobiNav = document.querySelector('#mobileSideNav')
  let mobiNavLinks = Array.from(document.querySelectorAll('aside'))
  let body = document.querySelector('body')
  if(mobiNav.classList.contains('reveal')) {
    body.classList.remove('side-menu-open')
    mobiNav.classList.remove('reveal')
    mobiNavLinks.forEach(link => {link.classList.remove('reveal')})
  }
})

document.addEventListener('turbo:load', () => {
  AddToCartForms.loadBottomForm()
  AddToCart.AddToCart()
  CartNav.addCartToNavbar()
  DepositRedemptions.findCustomerDeposits()
  Checkout.loadWithAddOn()
  Dropdown.loadFullDropdown()
  Dropdown.captureEvent()
  Dropdown.assignEventsOrder()
  ImageSwap.exchangeImage()
  ImageSwap.showFirstEventImage()
  // Sildeout Nav for Mobile
  SideNav.closeSideNav()
  SideNav.closeAllMenus()
  SideNav.openSideNav()
  SideNav.assignSubMenuTarget()
  SideNav.assignSubMenuBackBtn()

  // Subnav on Event and Package Pages
  EventNav.addScrollingClasses()

  // Slider Info form
  InfoFormSlider.loadSliderForm()
  VueLoader.loadVueComponent(HotelIndex, document.getElementById('hotelIndex'), store)

})

