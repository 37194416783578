import svgPanZoom from 'svg-pan-zoom'
import { resetTooltip } from '../shared/tooltip'

document.addEventListener('turbo:load', () => {
  zoomSvg()
})

function zoomSvg() {
  var visibleSVGDiv = $('.seating-chart-image.zoomed:visible')[0]
  if (!visibleSVGDiv) return
  var svg = visibleSVGDiv.querySelector('#Layer_1')
  var svgChart = svgPanZoom(svg, { zoomEnabled: false, panEnabled: false })
  var svgPoint = visibleSVGDiv.querySelector(`#${visibleSVGDiv.dataset.position}`)
  var x1 = parseInt(svgPoint.getAttribute('x1'))
  var x2 = parseInt(svgPoint.getAttribute('x2'))
  var y1 = parseInt(svgPoint.getAttribute('y1'))
  var y2 = parseInt(svgPoint.getAttribute('y2'))
  var xMean = (x1 + x2) / 2
  var yMean = (y1 + y2) / 2
  if(!xMean) {
    var coord = svgPoint.getBBox()
    xMean = parseInt(coord.x)
    yMean = parseInt(coord.y)
  }
  svgChart.zoom(2)

  var realZoom= svgChart.getSizes().realZoom
  var vw = svgChart.getSizes().viewBox.width
  var vh = svgChart.getSizes().viewBox.height
  var xOffset = getOffset(vw, xMean)
  var yOffset = getOffset(vh, yMean)

  svgChart.pan({
    x: -(xMean * realZoom)+(svgChart.getSizes().width/xOffset),
    y: -(yMean * realZoom)+(svgChart.getSizes().height/yOffset)
  })
  let tooltip = document.getElementById('svg-tooltip')
  setTimeout(function(){ resetTooltip(tooltip) }, 1500)
}

function getOffset(viewboxUnit, position) {
  var diff = position - viewboxUnit / 3
  if(diff < 0) {
    return 3.5
  } else if (diff > viewboxUnit / 3) {
    return 1.5
  } else {
    return 2
  }
}
