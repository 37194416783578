export default {
  addScrollingClasses() {
    if (isCollapsible) {
      window.addEventListener('scroll', () => {
        let scroll = window.scrollY
        toggleEventNav(scroll)
      })
    }
  }
}

const isCollapsible = () => $('main').height() > $(window).height()

const toggleEventNav = (position) => {
  let primaryNav = document.getElementById('gpePrimaryNav')
  let eventNav = document.getElementById('gpeEventNav')
  let body = document.querySelector('body')

  if (position > 50) {
    if (primaryNav && eventNav) { primaryNav.classList.add('demote') }
    if (eventNav) { eventNav.classList.add('promote') }
    if (body) { body.classList.add('nav-shift') }
  } else {
    if (primaryNav && eventNav) { primaryNav.classList.remove('demote') }
    if (eventNav) { eventNav.classList.remove('promote') }
    if (body) { body.classList.remove('nav-shift') }
  }
}
